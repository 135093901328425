@tailwind base;
@tailwind components;
@tailwind utilities;

.light-mode {
  background-color: rgba(252, 252, 252, 1); /* Light background color */
  color: rgba(19, 19, 19, 1); /* Dark text color */
  border-color: #cccccc; /* Light border color */
  /* Additional styles for light mode */
}

/* For Dark Mode */
.dark-mode {
  background-color: rgba(19, 19, 19, 1); /* Dark background color */
  color: rgba(252, 252, 252, 1); /* Light text color */
  border-color: #333333; /* Dark border color */
  /* Additional styles for dark mode */
}


@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

* {

  font-family: 'Outfit', sans-serif;

}


